import React, { useEffect, useState } from "react";
import _ from "lodash";
import MediaObject from "./MediaObject";
import Button from "@mui/material/Button";
import { Box, Stack, Typography } from "@mui/material";
import DisplayMediaObjects from "../MediaObject/DisplayMediaObjects";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { MediaObject as MediaObjectType } from "../../types/MediaObject";
import MediaObjectPopUpMenu from "./MediaObjectPopUpMenu";

type MediaObjectListViewProps = {
  mediaObjects: MediaObjectType[];
};

export default function MediaObjectListView({ mediaObjects }: MediaObjectListViewProps) {
  const [showAllMediaObjects, setShowAllMediaObjects] = useState(false);
  const [mediaObjectToPreview, setMediaObjectToPreview] = useState<MediaObjectType | null>(null);
  const queryClient = useQueryClient();

  const toggleShowAll = () => {
    setShowAllMediaObjects(!showAllMediaObjects);
  };

  const handleSetMediaObjectToPreview = (mediaObject: MediaObjectType) => {
    setMediaObjectToPreview(mediaObject);
  };

  const mediaObjectsToDisplay = showAllMediaObjects ? mediaObjects : mediaObjects.slice(0, 3);

  useEffect(() => {
    if (mediaObjectToPreview) {
      setMediaObjectToPreview(mediaObjects.find((mediaObjects) => mediaObjects.id === mediaObjectToPreview.id) ?? null);
    }
  }, [mediaObjects]);

  const refreshMediaObjects = async () => {
    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));
  };

  return (
    <>
      {mediaObjectsToDisplay.length > 0 ? (
        _.map(mediaObjectsToDisplay, (mediaObject) => (
          <Box key={mediaObject.id} sx={{ marginBottom: "2rem" }}>
            <MediaObject
              mediaObject={mediaObject}
              updateMediaObjects={refreshMediaObjects}
              handleOpenMediaObject={handleSetMediaObjectToPreview}
            />
          </Box>
        ))
      ) : (
        <Box sx={{ textAlign: "center", color: "#dcdcdc", margin: "2rem" }}>
          <Typography variant={"h4"}>Es sind noch keine Dokumente vorhanden</Typography>
        </Box>
      )}
      {mediaObjects.length > 3 && (
        <Button onClick={toggleShowAll} sx={{ margin: "0 auto" }}>
          {showAllMediaObjects ? "Weniger Anzeigen" : "Alle Anzeigen"}
        </Button>
      )}
      <DisplayMediaObjects
        setMediaObjectToDisplay={setMediaObjectToPreview}
        mediaObjects={mediaObjects}
        mediaObjectToDisplay={mediaObjectToPreview}
        titleElement={
          mediaObjectToPreview ? (
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} flexGrow={1}>
              <MediaObjectPopUpMenu mediaObject={mediaObjectToPreview} updateMediaObjects={refreshMediaObjects} />
            </Stack>
          ) : null
        }
      />
    </>
  );
}
