import { AbstractCase } from "../../../types/AbstractCase";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { apiPost } from "../../../services/Api/apiCall";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { enqueueSnackbar } from "notistack";
import { queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { Stack } from "@mui/material";
import { CasePdfGeneratorFormValues } from "../../../types/CasePdfGenerator";
import { UseFormReturn } from "react-hook-form";
import { editorStateToHTML } from "../../../services/Editor/editorFunctions";

type CaseSaveDraftProps = {
  product: AbstractCase;
  formContext: UseFormReturn<CasePdfGeneratorFormValues>;
};

const PdfGeneratorDraftSave = ({ product, formContext }: CaseSaveDraftProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const saveDraft = async () => {
    try {
      setIsLoading(true);

      const { content, ...restOfFormData } = formContext.getValues();
      const htmlEditorContent = content ? editorStateToHTML(content) : null;

      await apiPost("form_drafts", {
        title: "Entwurf_" + formContext.getValues().filename + ".pdf",
        formData: {
          ...restOfFormData,
          content: htmlEditorContent,
        },
        backofficeCase: product.backofficeCase["@id"],
        creator: currentUser["@id"],
      });
      await queryClient.invalidateQueries(
        queryKeys.collection("form_drafts", { backofficeCase: product.backofficeCase.id })
      );
    } catch (error) {
      enqueueSnackbar("Es ist ein Fehler aufgetreten", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack direction={"row"} justifyContent={"center"}>
      <ButtonLoading variant={"text"} fullWidth={false} onClick={saveDraft} isLoading={isLoading}>
        Entwurf Speichern
      </ButtonLoading>
    </Stack>
  );
};
export default PdfGeneratorDraftSave;
