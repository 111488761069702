import { Divider, IconButton, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIconOutlined from "@mui/icons-material/DeleteOutline";
import React from "react";
import { deleteResource, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FormDraft from "../../../types/FormDraft";
import { UseFormReturn } from "react-hook-form";
import { CasePdfGeneratorFormValues } from "../../../types/CasePdfGenerator";
import { HashLink } from "react-router-hash-link";
import { htmlToEditorState } from "../../../services/Editor/editorFunctions";

type PdfGeneratorDraftListItemProps = {
  formDraft: FormDraft;
  formContext: UseFormReturn<CasePdfGeneratorFormValues>;
};
const PdfGeneratorDraftListItem = ({ formDraft, formContext }: PdfGeneratorDraftListItemProps) => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteResource);

  const loadDraft = () => {
    const { content, ...restOfFormData } = formDraft.formData;
    const restoredEditorState = htmlToEditorState(content);

    formContext.reset({
      ...restOfFormData,
      content: restoredEditorState,
    });
  };

  const handleDelete = async () => {
    try {
      await deleteMutation.mutateAsync({ uri: "form_drafts", id: formDraft.id });
      await queryClient.invalidateQueries(queryKeys.collection("form_drafts"));
    } catch (error) {
      enqueueSnackbar("Es ist ein Fehler aufgetreten", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  return (
    <>
      <ListItem key={formDraft.id}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
          <Typography>{formDraft.title + " | " + formDraft.creator.person.fullname}</Typography>
          <Stack direction={"row"} spacing={1}>
            <Tooltip title="Entwurf bearbeiten">
              <IconButton component={HashLink} onClick={loadDraft} to={"#lbEditorAnchor"}>
                <EditIcon color={"primary"} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Entwurf löschen">
              <IconButton onClick={handleDelete}>
                <DeleteIconOutlined color={"primary"} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </ListItem>
      <Divider />
    </>
  );
};
export default PdfGeneratorDraftListItem;
