import React from "react";
import Grid from "@mui/material/Grid";
import CircleIcon from "../CircleIcon/CircleIcon";
import iconPdf from "../../assets/icon/picture_as_pdf-24px.svg";
import iconAttachment from "../../assets/icon/attach_file-24px.svg";
import iconImage from "../../assets/icon/collections-24px.svg";
import PopUpMenu from "../PopUpMenu/PopUpMenu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { getMediaObjectSize } from "../../services/formServiceFunctions";
import { getCreatedBy } from "../../services/backofficeUserService";
import MediaObjectChips from "./MediaObjectChips";
import DisplayOrDownloadMediaObjectWrapper from "../ElectronicFileFolder/DisplayOrDownloadMediaObjectWrapper";
import { Box, Hidden } from "@mui/material";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { Star } from "@mui/icons-material";
import mediaObjectStyle from "./mediaObjectStyle";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { MediaObject as MediaObjectType } from "../../types/MediaObject";
import MediaObjectPopUpMenu from "./MediaObjectPopUpMenu";

type MediaObjectProps = {
  mediaObject: MediaObjectType;
  updateMediaObjects: Function;
  isNonInteractive?: boolean;
  handleOpenMediaObject: Function;
  folderStructureView?: boolean;
};

const MediaObject = ({
  mediaObject,
  updateMediaObjects,
  isNonInteractive = false,
  handleOpenMediaObject,
  folderStructureView = false,
}: MediaObjectProps) => {
  const currentUser = useCurrentUser();
  const { backofficeUsers } = useBackofficeUser();

  const getIconData = (mediaObject: MediaObjectType) => {
    if (mediaObject.isFavourite) {
      return { icon: <Star sx={{ color: "primary.main" }} />, type: "muiIcon" };
    }
    let icon = iconAttachment;

    switch (mediaObject.mimeType) {
      case "image/jpg":
      case "image/jpeg":
      case "image/png":
        icon = iconImage;
        break;
      case "application/pdf":
        icon = iconPdf;
        break;
      default:
        break;
    }
    return { icon: icon, type: "image" };
  };

  //need some sort of info if the user is an admin
  const isExternal = currentUser.isExternal;
  const isExternalEditAllowed = !isExternal || mediaObject.createdBy === currentUser["@id"];

  const createdAt = moment(mediaObject.created);
  const { icon, type } = getIconData(mediaObject);

  return (
    <Grid container wrap={"nowrap"}>
      <Hidden only={"xs"}>
        <Grid item sx={mediaObjectStyle.icon}>
          <CircleIcon icon={icon} type={type} />
        </Grid>
      </Hidden>
      <Grid item sx={mediaObjectStyle.item}>
        <Grid container>
          <Grid item xs={12}>
            {!isNonInteractive ? (
              <DisplayOrDownloadMediaObjectWrapper
                mediaObject={mediaObject}
                setMediaObjectToDisplay={handleOpenMediaObject}
              >
                <Box sx={mediaObjectStyle.mediaObjectName}>{mediaObject.originalName}</Box>
              </DisplayOrDownloadMediaObjectWrapper>
            ) : (
              <Box sx={mediaObjectStyle.headline}>{mediaObject.originalName}</Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              ...mediaObjectStyle.description,
              ...(mediaObject.isFavourite ? mediaObjectStyle.bold : {}),
            }}
          >
            {mediaObject.description}
          </Grid>
          <Grid item>
            Hinzugefügt durch {getCreatedBy(mediaObject.createdBy, backofficeUsers)} am {createdAt.format("DD.MM.YYYY")}{" "}
            um {createdAt.format("HH:mm")} Uhr | {getMediaObjectSize(mediaObject.size)}
          </Grid>
          {!isExternal && (
            <Grid item xs={12}>
              <MediaObjectChips mediaObject={mediaObject} showFolder={!folderStructureView} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isNonInteractive && isExternalEditAllowed && (
        <Grid item>
          <MediaObjectPopUpMenu mediaObject={mediaObject} updateMediaObjects={updateMediaObjects} />
        </Grid>
      )}
      {isExternal && !isExternalEditAllowed && (
        <Grid item>
          <PopUpMenu>
            <MenuItem component={"a"} download={true} href={mediaObject.downloadUrl}>
              Herunterladen
            </MenuItem>
          </PopUpMenu>
        </Grid>
      )}
    </Grid>
  );
};

export default MediaObject;
