import PopUpMenu from "../PopUpMenu/PopUpMenu";
import MenuItem from "@mui/material/MenuItem";
import { STAGE_ACTION_REQUIRED, STAGE_DATA_COMPLETED } from "../../services/Stages/StagesCase";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import Button from "@mui/material/Button";
import ChangeMediaObjectDescriptionModal from "../ElectronicFileFolder/ChangeMediaObjectDescriptionModal";
import ChangeMediaObjectVisibilityModal from "../ElectronicFileFolder/ChangeMediaObjectVisibilityModal";
import ChangeMediaObjectFolderModal from "../ElectronicFileFolder/ChangeMediaObjectFolderModal";
import ChangeMediaObjectIdentifierModal from "../ElectronicFileFolder/ChangeMediaObjectIdentifierModal";
import CopyMediaObjectModal from "../ElectronicFileFolder/CopyMediaObjectModal";
import ChangeFieldNameModal from "../ElectronicFileFolder/ChangeFieldNameModal";
import React, { useState } from "react";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { MediaObject } from "../../types/MediaObject";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCase } from "../../provider/CaseProvider";

type MediaObjectPopUpMenuProps = {
  mediaObject: MediaObject;
  updateMediaObjects: Function;
};

const MediaObjectPopUpMenu = ({ mediaObject, updateMediaObjects }: MediaObjectPopUpMenuProps) => {
  const [deleteMediaObjectOpen, setDeleteMediaObjectOpen] = useState(false);
  const [changeDescriptionOpen, setChangeDescriptionOpen] = useState(false);
  const [changeVisibilityOpen, setChangeVisibilityOpen] = useState(false);
  const [selectSubfolderOpen, setSelectSubfolderOpen] = useState(false);
  const [changeIdentifierOpen, setChangeIdentifierOpen] = useState(false);
  const [copyMediaObjectOpen, setCopyMediaObjectOpen] = useState(false);
  const [changeFieldNameOpen, setChangeFieldNameOpen] = useState(false);

  const updateMutation = useMutation(updateResource);
  const currentUser = useCurrentUser();

  const deleteAllowed = currentUser;
  const isExternal = currentUser.isExternal;

  const { product } = useCase();

  const handleDeleteMediaObjectClick = () => {
    updateMutation
      .mutateAsync({
        uri: "media_objects",
        id: mediaObject.id,
        data: {
          deleted: true,
          showInGallery: false,
          showInExternalView: false,
        },
      })
      .then(() => updateMediaObjects());
    setDeleteMediaObjectOpen(false);
  };

  const handleMarkAsFavourite = (isFavourite: boolean) => {
    updateMutation
      .mutateAsync({
        uri: "media_objects",
        id: mediaObject.id,
        data: {
          isFavourite: isFavourite,
        },
      })
      .then(() => updateMediaObjects());
  };

  return (
    <>
      <PopUpMenu>
        <MenuItem onClick={() => setChangeDescriptionOpen(true)}>Beschreibung ändern</MenuItem>
        {!isExternal && <MenuItem onClick={() => setChangeVisibilityOpen(true)}>Sichtbarkeit bearbeiten</MenuItem>}
        {!isExternal && (
          <MenuItem onClick={() => setSelectSubfolderOpen(true)}>{"In anderen Ordner schieben"}</MenuItem>
        )}
        {!isExternal && <MenuItem onClick={() => setChangeIdentifierOpen(true)}>Art des Dokuments ändern</MenuItem>}
        {!isExternal && <MenuItem onClick={() => setCopyMediaObjectOpen(true)}>Als Anlage duplizieren</MenuItem>}
        {!isExternal &&
          product?.productCategory === "contractLaw" &&
          product.stage &&
          product.stage >= STAGE_DATA_COMPLETED &&
          product.stage <= STAGE_ACTION_REQUIRED &&
          mediaObject.fieldname !== "extrajudicialLetter" && (
            <MenuItem onClick={() => setChangeFieldNameOpen(true)}>Als außergerichtl. Schreiben kennzeichnen</MenuItem>
          )}
        <MenuItem component="a" download={mediaObject.originalName} href={mediaObject.downloadUrl}>
          Herunterladen
        </MenuItem>
        {!isExternal && (
          <MenuItem onClick={() => handleMarkAsFavourite(!mediaObject.isFavourite)}>
            {mediaObject.isFavourite ? "Als Favorit entfernen" : "Als Favorit markieren"}
          </MenuItem>
        )}
        {deleteAllowed && <MenuItem onClick={() => setDeleteMediaObjectOpen(true)}>Löschen</MenuItem>}
      </PopUpMenu>
      {deleteAllowed && (
        <LegalbirdIoModal
          handleClose={() => setDeleteMediaObjectOpen(false)}
          open={deleteMediaObjectOpen}
          title={"Datei löschen?"}
          submitButton={
            <Button variant={"contained"} onClick={handleDeleteMediaObjectClick}>
              Ja
            </Button>
          }
        >
          <p className={"center"}>
            Diese Datei wird unwiderruflich gelöscht. Sind Sie sicher, dass Sie die Datei löschen möchten?
          </p>
        </LegalbirdIoModal>
      )}
      <ChangeMediaObjectDescriptionModal
        setChangeDescriptionOpen={setChangeDescriptionOpen}
        changeDescriptionOpen={changeDescriptionOpen}
        mediaObject={mediaObject}
        updateMediaObjects={updateMediaObjects}
      />
      <ChangeMediaObjectVisibilityModal
        mediaObject={mediaObject}
        updateMediaObjects={updateMediaObjects}
        changeVisibilityOpen={changeVisibilityOpen}
        setChangeVisibilityOpen={setChangeVisibilityOpen}
      />
      <ChangeMediaObjectFolderModal
        setSelectSubfolderOpen={setSelectSubfolderOpen}
        updateMediaObjects={updateMediaObjects}
        mediaObject={mediaObject}
        selectSubfolderOpen={selectSubfolderOpen}
      />
      <ChangeMediaObjectIdentifierModal
        setChangeIdentifierOpen={setChangeIdentifierOpen}
        changeIdentifierOpen={changeIdentifierOpen}
        mediaObject={mediaObject}
        updateMediaObjects={updateMediaObjects}
      />
      <CopyMediaObjectModal
        setCopyMediaObjectOpen={setCopyMediaObjectOpen}
        copyMediaObjectOpen={copyMediaObjectOpen}
        mediaObject={mediaObject}
        updateMediaObjects={updateMediaObjects}
      />
      <ChangeFieldNameModal
        mediaObject={mediaObject}
        updateMediaObjects={updateMediaObjects}
        changeFieldNameOpen={changeFieldNameOpen}
        setChangeFieldNameOpen={setChangeFieldNameOpen}
      />
    </>
  );
};

export default MediaObjectPopUpMenu;
